<template>
   <div>
      <Topbar />
      <div class="subheader py-3 py-lg-8 subheader-transparent" v-bind:class="subheaderClasses" id="kt_subheader">
         <div
            class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
            v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
         >
            <div class="d-flex align-items-baseline mr-5">
               <h2 class="subheader-title text-dark font-weight-bold my-2 mr-3">
                  {{ title }}
               </h2>
            </div>
            <div class="d-flex align-items-center w-25" v-if="currentUser.customer">
               <h3 class="subheader-title text-muted font-weight-light my-2 ml-auto">
                  {{ currentUser.customer.name }}
               </h3>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Topbar from '@/view/layout/header/Topbar';

export default {
   name: 'KTSubheader',
   props: {
      title: String
   },
   components: {
      Topbar
   },
   computed: {
      ...mapGetters(['layoutConfig', 'currentUser']),

      /**
       * Check if subheader width is fluid
       */
      widthFluid() {
         return this.layoutConfig('subheader.width') === 'fluid';
      },

      subheaderClasses() {
         const classes = [];
         const style = this.layoutConfig('subheader.style');
         if (style) {
            classes.push(style);

            if (style === 'solid') {
               classes.push('bg-white');
            }

            if (this.layoutConfig('subheader.fixed')) {
               classes.push('border-top');
            }

            if (this.layoutConfig('subheader.clear')) {
               classes.push('mb-0');
            }
         }
         return classes.join(' ');
      }
   }
};
</script>
