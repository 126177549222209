<template>
   <ul class="menu-nav">
      <router-link :to="{ name: 'home' }" v-slot="{ href, navigate }">
         <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="$route.name == 'home' && 'menu-item-active'">
            <a :href="href" class="menu-link" @click="navigate">
               <i class="menu-icon fas fa-home"></i>
               <span class="menu-text">{{ $t('MENU.HOME') }}</span>
            </a>
         </li>
      </router-link>

      <router-link
         :to="{ name: 'action.list', params: { type: 'grid' } }"
         v-slot="{ href, navigate }"
         v-if="this.$store.getters.getRole.name === 'Gestionnaire'"
      >
         <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="$route.name == 'action.list' && 'menu-item-active'">
            <a :href="href" class="menu-link" @click="navigate">
               <i class="menu-icon far fa-calendar-alt"></i>
               <span class="menu-text">{{ $t('MENU.PLANNING') }}</span>
            </a>
         </li>
      </router-link>

      <router-link
         :to="{ name: 'action.closed', params: { type: 'grid' } }"
         v-slot="{ href, navigate }"
         v-if="this.$store.getters.getRole.name === 'Gestionnaire'"
      >
         <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="$route.name == 'action.closed' && 'menu-item-active'">
            <a :href="href" class="menu-link" @click="navigate">
               <i class="menu-icon far fa-calendar-times"></i>
               <span class="menu-text">{{ $t('MENU.CLOSED_TRAININGS') }}</span>
            </a>
         </li>
      </router-link>

      <router-link :to="{ name: 'calendar' }" v-slot="{ href, navigate }" v-if="this.$store.getters.getRole.name === 'Gestionnaire'">
         <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="$route.name == 'calendar' && 'menu-item-active'">
            <a :href="href" class="menu-link" @click="navigate">
               <i class="menu-icon far fa-calendar"></i>
               <span class="menu-text">{{ $t('ACTION.GENERAL.CALENDAR') }}</span>
            </a>
         </li>
      </router-link>

      <router-link :to="{ name: 'intern.list' }" v-slot="{ href, navigate }" v-if="this.$store.getters.getRole.name === 'Gestionnaire'">
         <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="$route.name == 'intern.list' && 'menu-item-active'">
            <a :href="href" class="menu-link" @click="navigate">
               <i class="menu-icon fas fa-users"></i>
               <span class="menu-text">{{ $t('MENU.INTERNS') }}</span>
            </a>
         </li>
      </router-link>
      <router-link :to="{ name: 'invoice.list' }" v-slot="{ href, navigate }" v-if="this.$store.getters.getRole.name !== 'Stagiaire' && invoice">
         <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="$route.name == 'invoice.list' && 'menu-item-active'">
            <a :href="href" class="menu-link" @click="navigate">
               <i class="menu-icon fas fa-file-invoice-dollar"></i>
               <span class="menu-text">{{ $t('MENU.INVOICES') }}</span>
            </a>
         </li>
      </router-link>

      <router-link :to="{ name: 'evacuation.list' }" v-slot="{ href, navigate }" v-if="this.$store.getters.getRole.name === 'Gestionnaire'">
         <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="$route.name === 'evacuation.list' && 'menu-item-active'">
            <a :href="href" class="menu-link" @click="navigate">
               <i class="menu-icon fas fa-fire"></i>
               <span class="menu-text">{{ $t('MENU.EVACUATIONS') }}</span>
            </a>
         </li>
      </router-link>

      <router-link :to="{ name: '_intern.action.list' }" v-slot="{ href, navigate }" v-if="this.$store.getters.getRole.name === 'Stagiaire'">
         <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="$route.name == '_intern.action.list' && 'menu-item-active'">
            <a :href="href" class="menu-link" @click="navigate">
               <i class="menu-icon far fa-calendar-alt"></i>
               <span class="menu-text">{{ $t('_INTERN.MENU.ACTIONS') }}</span>
            </a>
         </li>
      </router-link>
      <router-link :to="{ name: 'internship-notice' }" v-slot="{ href, navigate }" v-if="role === 'Gestionnaire' && currentUser.avisDeStage === 1">
         <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="$route.name == 'internship-notice' && 'menu-item-active'">
            <a :href="href" class="menu-link" @click="navigate">
               <i class="menu-icon fas fa-star"></i>
               <span class="menu-text">{{ $t('INTERNSHIP_NOTICE.MENU.TITLE') }}</span>
            </a>
         </li>
      </router-link>
      <router-link
         :to="{ name: 'statistiques' }"
         v-slot="{ href, navigate }"
         v-if="
            (role === 'Gestionnaire' && currentUser.tauxEtSeuil === true) || currentUser.role === 'Administrateur' || currentUser.role === 'Super Admin'
         "
      >
         <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="$route.name == 'statistiques' && 'menu-item-active'">
            <a :href="href" class="menu-link" @click="navigate">
               <i class="menu-icon far fa-chart-bar"></i>
               <span class="menu-text">{{ $t('MENU.STATISTIQUES') }}</span>
            </a>
         </li>
      </router-link>
   </ul>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
   name: 'KTMenu',
   methods: {
      hasActiveChildren(match) {
         return this.$route['path'].indexOf(match) !== -1;
      }
   },
   async mounted() {
      const rights = this.$store.getters.listRights;
      rights.forEach((right) => {
         if (right.name === 'Factures') {
            this.invoice = true;
         }
      });
   },
   computed: {
      ...mapGetters({
         currentUser: 'currentUser'
      })
   },
   data() {
      return {
         invoice: null,
         role: this.$store.getters.getRole.name
      };
   }
};
</script>
