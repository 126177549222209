<template>
   <div>
      <ul class="sticky-toolbar nav flex-column pl-2 pr-2 pt-2">
         <li v-b-tooltip.hover.left="text" class="nav-item mb-2">
            <span class="btn btn-sm btn-icon btn-bg-info btn-text-light btn-hover-light cursor-help">
               <i class="fas fa-info text-white"></i>
            </span>
         </li>
      </ul>
   </div>
</template>

<script>
export default {
   props: ['text']
};
</script>
