<template>
   <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
      <div class="aside-primary d-flex flex-column align-items-center flex-row-auto">
         <Brand></Brand>
         <div
            class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull ps"
            style="height: 528px; overflow: hidden"
         >
            <ul class="nav flex-column" role="tablist">
               <li
                  class="nav-item mb-3"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window"
                  v-b-tooltip.hover.right="'Menu principal'"
               >
                  <a href="#" class="nav-link btn btn-icon btn-clean btn-lg active" data-toggle="tab" v-on:click="setActiveTab" data-tab="0">
                     <span class="svg-icon svg-icon-xl">
                        <inline-svg src="/media/svg/icons/Text/Bullet-list.svg" />
                     </span>
                  </a>
               </li>
            </ul>
         </div>
         <div class="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
            <span
               class="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
               id="kt_aside_toggle"
               ref="kt_aside_toggle"
               data-placement="right"
               data-container="body"
               data-boundary="window"
               v-b-tooltip.hover.right="'Changer la vue'"
            >
               <i class="ki ki-bold-arrow-back icon-sm"></i>
            </span>
            <!-- <QuickUser></QuickUser> -->
         </div>
      </div>
      <div class="aside-secondary d-flex flex-row-fluid">
         <div class="aside-workspace scroll scroll-push my-2 ps d-flex flex-column justify-content-between" style="height: 824px; overflow: hidden">
            <b-tabs class="hide-tabs" v-model="tabIndex">
               <b-tab>
                  <div class="aside-menu-wrapper flex-column-fluid px-10 py-5" id="kt_aside_menu_wrapper">
                     <div
                        ref="kt_aside_menu"
                        id="kt_aside_menu"
                        class="aside-menu"
                        data-menu-vertical="1"
                        data-menu-scroll="1"
                        v-bind:class="asideMenuClass"
                     >
                        <perfect-scrollbar class="aside-menu scroll" style="max-height: 90vh; position: relative">
                           <Menu></Menu>
                        </perfect-scrollbar>
                     </div>
                  </div>
               </b-tab>
            </b-tabs>
            <!--end::Tab Content-->
            <!--end::Workspace-->
            <div>
               <img src="/media/logos/logo-si2p.png" class="w-75 mx-0 d-block position-relative" alt="Logo du réseau Si2p" />
            </div>
         </div>
         <!--end::Secondary-->
      </div>
   </div>
   <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
   display: none;
}
</style>

<script>
import { mapGetters } from 'vuex';
import KTLayoutAside from '@/assets/js/layout/base/aside.js';
import KTLayoutAsideMenu from '@/assets/js/layout/base/aside-menu.js';
import KTLayoutAsideToggle from '@/assets/js/layout/base/aside-toggle.js';
import Brand from '@/view/layout/brand/Brand.vue';
import Menu from '@/view/layout/aside/Menu.vue';
import { GET_ROLE } from '@/core/services/store/api/intern';
/* import QuickUser from '@/view/layout/extras/offcanvas/QuickUser.vue' */

export default {
   name: 'KTAside',
   data: () => ({
      insideTm: 0,
      outsideTm: 0,
      tabIndex: 0,
      role: null
   }),
   components: {
      Brand,
      Menu
   },
   async mounted() {
      this.role = this.$store.getters.getRole.name;
      this.$nextTick(() => {
         // Init Aside
         KTLayoutAside.init(this.$refs['kt_aside']);

         // Init Aside Menu
         KTLayoutAsideMenu.init(this.$refs['kt_aside_menu']);

         // Init Aside Toggle
         KTLayoutAsideToggle.init(this.$refs['kt_aside_toggle']);
      });
      //get role of the user by user id
      await this.$store.dispatch(GET_ROLE, this.currentUser.id).then((res) => {
         this.role = res.name;
      });
   },
   methods: {
      setActiveTab(event) {
         let target = event.target;
         if (!event.target.classList.contains('nav-link')) {
            target = event.target.closest('.nav-link');
         }

         const tab = target.closest('[role="tablist"]');
         const links = tab.querySelectorAll('.nav-link');
         // remove active tab links
         for (let i = 0; i < links.length; i++) {
            links[i].classList.remove('active');
         }

         // set clicked tab index to bootstrap tab
         this.tabIndex = parseInt(target.getAttribute('data-tab'));

         // set current active tab
         target.classList.add('active');
      }
   },
   computed: {
      ...mapGetters(['layoutConfig', 'getClasses', 'currentUser']),

      /**
       * Get extra classes for menu based on the options
       */
      asideMenuClass() {
         const classes = this.getClasses('aside_menu');
         if (typeof classes !== 'undefined') {
            return classes.join(' ');
         }
         return null;
      }
   }
};
</script>
